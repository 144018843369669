<template>
  <b-modal
    v-if="isContentDefined"
    id="view-content-modal"
    ref="view-content-modal"
    centered
    hide-footer
    hide-header
    size="md"
    @hide="closeModal"
  >
    <h3 class="text-center font-weight-semi-bold text-color-1 mb-9">
      <inline-svg
        :src="require(`@/assets/icons/library-view-content.svg`)"
        :height="30"
        :width="30"
      ></inline-svg>
      {{ $t(`libraryContent.viewContentModalHeading`) }}
    </h3>
    <b-container fluid>
      <b-row class="px-2 py-5 rounded bg-light">
        <b-col sm="12" md="4">
          <b-img
            ref="cover_image"
            :src="content.cover_image"
            height="100"
            class="cursor-pointer border-radius-5 img-contain h-auto w-100"
          />
        </b-col>
        <b-col sm="12" md="8" class="mentorcast-content">
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            label="Item Title:"
            class="align-items-center position-relative border-0"
          >
            <p class="font-weight-bold">{{ content.title }}</p>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            label="Distributor:"
            class="align-items-center position-relative"
          >
            <p class="">
              <router-link
                class="text-underline text-light-blue"
                :to="{
                  name: 'MentorProfilePage',
                  params: { id: content.program.mentor.uuid }
                }"
              >
                {{ content.program.mentor.name }}
              </router-link>
            </p>
          </b-form-group>
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="4"
            content-cols-sm="8"
            content-cols-lg="8"
            label="Content Type:"
            class="align-items-center position-relative"
          >
            <p class="">
              <inline-svg
                :src="content_type.icon"
                :height="20"
                :width="20"
              ></inline-svg>
              <span class="d-inline-block ml-3">{{ content_type.label }}</span>
            </p>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col cols="12" class="my-3">
          <b-form-checkbox
            id="checkbox-1"
            v-model="termsConditionsCheck"
            name="checkbox-1"
            :unchecked-value="false"
            class="mt-2"
          >
            I confirm that I will not distribute the purchased content without
            permission
          </b-form-checkbox>
        </b-col>
        <b-col cols="12">
          <div class="w-100 d-flex justify-content-center">
            <b-button
              v-if="!isMentor"
              :disabled="!termsConditionsCheck"
              class="btn main-btn main-btn-sm mr-4"
              variant="primary"
              @click="downloadItem"
            >
              Download
            </b-button>
            <b-button
              class="btn main-btn main-btn-sm"
              @click="$bvModal.hide('view-content-modal')"
            >
              Cancel
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ContentViewModal",
  components: {},
  props: {
    content: Object,
    isMentor: Boolean
  },
  data() {
    return {
      termsConditionsCheck: false,
      content_types: [
        {
          label: "Book",
          value: "book",
          icon: require("@/assets/icons/library-book.svg")
        },
        {
          label: "Multimedia",
          value: "media",
          icon: require("@/assets/icons/library-media.svg")
        },
        {
          label: "Document",
          value: "document",
          icon: require("@/assets/icons/library-docs.svg")
        },
        {
          label: "Other",
          value: "other",
          icon: require("@/assets/icons/library-others.svg")
        }
      ],
      content_type: {}
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    distributorName() {
      return this.content.user.first_name + " " + this.content.user.last_name;
    },
    isContentDefined() {
      return this.content !== null && typeof this.content === "object";
    }
  },
  mounted() {
    if (this.content) {
      this.content_type = this.content_types.filter(
        content => content.value === this.content.content_type
      )[0];
    }
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
    },
    downloadItem() {
      this.$store.dispatch("program/DOWNLOAD_PROGRAM_CONTENT", {
        program_id: this.content.program_id,
        content_id: this.content.id
      });
      this.$emit("refreshContent");
      if (this.content.content_type === "file") {
        const link = document.createElement("a");
        link.href = this.content.source;
        link.download = this.content.title;
        link.click();
      } else {
        window.open(this.content.source);
      }
      this.$bvModal.hide("view-content-modal");
    }
  }
});
</script>

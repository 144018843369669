
import Vue from "vue";
import { mapGetters } from "vuex";
import ClipperModal from "@/components/ClipperModal.vue";

interface Content {
  cover_image: string | File;
  title: string;
  content_type: string;
  content_src: "file" | "url";
  file_path: string | File;
  file_url: string;
}

export default Vue.extend({
  name: "AddContentModal",
  components: {
    ClipperModal
  },
  data() {
    return {
      content: {
        cover_image: "",
        title: "",
        content_type: "",
        content_src: "file",
        file_path: "",
        file_url: ""
      } as Content,
      errors: {} as any,
      title_word_limit: 100 as number,
      processing: false as boolean,
      defaultCover: require("@/assets/images/default-content-cover.png"),
      cover_blob_url: "",
      content_types: [
        {
          label: "Book",
          value: "book",
          icon: require("@/assets/icons/library-book.svg")
        },
        {
          label: "Multimedia",
          value: "media",
          icon: require("@/assets/icons/library-media.svg")
        },
        {
          label: "Document",
          value: "document",
          icon: require("@/assets/icons/library-docs.svg")
        },
        {
          label: "Other",
          value: "other",
          icon: require("@/assets/icons/library-others.svg")
        }
      ],
      termsConditionsCheck: false as boolean,
      distributionAllowed: false as boolean
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency"
    }),
    remainingTitleChar(): number {
      return this.title_word_limit - this.content.title.length;
    },
    canAddNow(): boolean {
      return this.distributionAllowed && this.termsConditionsCheck;
    },
    blockSubmit(): boolean {
      return !this.canAddNow || this.processing;
    }
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    async handleSessionContentCreate() {
      try {
        this.processing = true;
        this.errors = {};
        const data = this.buildData();
        await this.$store.dispatch("program/CREATE_PROGRAM_CONTENT", {
          program_id: this.$route.params.id,
          data
        });
        this.$emit("refreshContent");
        this.processing = false;
        this.resetForm();
        this.$bvModal.hide("add-content-modal");
      } catch (error) {
        this.processing = false;
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          console.error("An error occurred:", error.message);
        }
      }
    },
    handleDocUpload(event: Event): void {
      const fileInput = event.target as HTMLInputElement;
      const filePathElement = this.$refs.file_path as HTMLSpanElement;
      const file = fileInput.files?.[0] as File | undefined;

      if (file) {
        try {
          if (filePathElement) {
            filePathElement.textContent = file.name;
          }
          this.content.file_path = file;
        } catch (error) {
          console.error("Error processing file:", error);
        }
      } else {
        console.error("No file selected.");
      }
    },
    handleCoverUpload(event: Event): void {
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0] as File | undefined;
      if (file) {
        try {
          this.content.cover_image = file;
          this.cover_blob_url = URL.createObjectURL(file);
          this.$bvModal.show("clipper");
        } catch (error) {
          console.error("Error creating object URL:", error);
        }
      } else {
        console.error("No file selected.");
      }
    },
    buildData(): FormData {
      const formData = new FormData();
      for (const key in this.content) {
        const value = this.content[key as keyof Content];
        formData.append(key, value as string | Blob);
      }
      return formData;
    },
    setTempFile(data: any) {
      const imageElement = this.$refs.cover_image as HTMLImageElement;
      imageElement.src = data.url;
      this.content.cover_image = data.temp;
    },
    hasError(type: string): any {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    resetForm() {
      this.content.cover_image = "";
      this.content.title = "";
      this.content.content_type = "";
      this.content.content_src = "file";
      this.content.file_path = "";
      this.content.file_url = "";
      this.errors = {};
      this.distributionAllowed = false;
      this.termsConditionsCheck = false;
      this.defaultCover = require("@/assets/images/default-content-cover.png");
    },
    closeModal() {
      this.resetForm();
    }
  }
});


import Vue from "vue";
import { mapGetters } from "vuex";
import ContentItem from "@/components/MentoringPlan/Content/ContentItem.vue";
import ContentsSkeleton from "@/components/MentoringPlan/Content/ContentsSkeleton.vue";
import EditContentModal from "@/components/MentoringPlan/Content/EditContentModal.vue";
import DeleteContentModal from "@/components/MentoringPlan/Content/DeleteContentModal.vue";
import ViewContentModal from "@/components/MentoringPlan/Content/ViewContentModal.vue";
import AddContentModal from "@/components/MentoringPlan/Content/AddContentModal.vue";

export default Vue.extend({
  name: "ProgramContent",
  components: {
    ViewContentModal,
    DeleteContentModal,
    EditContentModal,
    ContentsSkeleton,
    ContentItem,
    AddContentModal
  },
  data() {
    return {
      selectedContent: null as null | any,
      isEditModalOpen: false as boolean,
      isViewModalOpen: false as boolean,
      loading: false,
      data: [] as any,
      page: 1 as number,
      load_more: false as boolean,
      last_page: 0 as number
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    }),
    isMentor(): boolean {
      return this.profile.type === "mentor";
    },
    previewImage(): string | null {
      if (this.selectedContent && "cover_image" in this.selectedContent) {
        return (this.selectedContent as { cover_image: string }).cover_image;
      }
      return null;
    }
  },
  mounted() {
    this.getSessionContent();
  },
  methods: {
    getSessionContent() {
      this.loading = true;
      this.$store
        .dispatch("program/GET_ALL_PROGRAM_CONTENT", {
          program_id: this.$route.params.id,
          data: {
            page: this.page
          }
        })
        .then(res => {
          this.loading = false;
          const data = res.data;
          console.log(data);

          this.page = data.current_page;
          this.load_more = this.page < data.last_page;
          this.data.push(...data.data);
        });
    },
    refreshContent() {
      this.data = [];
      this.page = 1;
      this.getSessionContent();
    },
    openEditModal({ content, name }: { content: any; name: string }) {
      console.log(content, name);
      this.isEditModalOpen = true;
      const modifiedContent = { ...this.modifyDataFor(content) };
      this.openModal(modifiedContent, name);
    },
    openDeleteModal({ content, name }: { content: any; name: string }) {
      console.log("oepn delete");
      this.isEditModalOpen = true;
      this.openModal(content, name);
    },
    openViewModal({
      content,
      name
    }: {
      content: any;
      name: string;
      purchase: boolean;
    }) {
      this.isViewModalOpen = true;
      this.openModal(content, name);
    },
    closeViewContentModal() {
      this.isViewModalOpen = false;
    },
    openModal(content: any, name: string) {
      this.selectedContent = content;
      this.$nextTick(() => this.$bvModal.show(name));
    },
    modifyDataFor(content: any) {
      content.content_src = content.content_source;
      if (content.content_src === "file") content.file_path = content.source;
      else content.file_url = content.source;
      return content;
    },
    onLoadMore() {
      this.page++;
      this.getSessionContent();
    }
  }
});
